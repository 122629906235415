define('app/calendar',[
    'jquery',
    'jquery.ui',
    'modernizr'
], function ($) {
    'use strict';

    var initCalendar = function (isDateClickable) {
        $.datepicker.regional.nl = {
            closeText: 'Sluiten',
            currentText: 'Vandaag',
            monthNames: ['januari', 'februari', 'maart', 'april', 'mei', 'juni',
                'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
            monthNamesShort: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun',
                'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
            dayNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
            dayNamesShort: ['zon', 'maa', 'din', 'woe', 'don', 'vri', 'zat'],
            dayNamesMin: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
            weekHeader: 'Wk',
            dateFormat: 'dd-mm-yy',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ''
        };
        $.datepicker.setDefaults($.datepicker.regional.nl);

        $(".datepicker-containers").datepicker({
            minDate: 0,
            onSelect: function () {
                var theDate = new Date(Date.parse($(this).datepicker("getDate")));
                var filterDateURL = ApiRouter.controllers.ProductionsController.byDateDayVelsen(
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    $.datepicker.formatDate("yy", theDate),
                    $.datepicker.formatDate("MM", theDate),
                    $.datepicker.formatDate("dd", theDate)
                ).url;
                window.location.href = filterDateURL;
            },
            beforeShowDay: function (date) {
                if (typeof isDateClickable === "undefined" || isDateClickable === null) {
                    return [true];
                } else {
                    var key = $.datepicker.formatDate("yy-mm-dd", date);
                    if (isDateClickable[key] !== undefined) {
                        if (isDateClickable[key] === false) {
                            return [false];
                        } else {
                            return [true];
                        }
                    } else {
                        return [false];
                    }
                }
            }
        });
    };

    return {
        init: function () {
            console.log('Start initializing calendar.js');
            $.get(ApiRouter.controllers.ProductionsFeedController.jsonCalendarBoolFeed().url, function (data) {
                initCalendar(data);
            }).fail(function () {
                initCalendar(null);
            });
        }
    };
});
