define('app/carousel',[
    'jquery',
    'modernizr',
    'bootstrap.all'
], function ($) {
    'use strict';

    return {
        init: function () {

            console.log('Start initializing carousel');

            var interval = 5000;
            var transition = 1500;
            $.fn.carousel.Constructor.TRANSITION_DURATION = transition;
            var carou =$('#myCarousel');
            carou.carousel({
                interval: interval
            });

            $(".carousel .item.youtube-item").click(function () {
                playVideo();
            });

            carou.on('slid.bs.carousel', function () {

                var slidelink = $(".carousel .item.active > a.slidelink").data("slidelink");
                if (typeof slidelink == "string" && slidelink.length > 4)
                {
                    //remove hrefs from non active slides
                    $(".carousel .item").not(".active").has("a.slidelink").removeAttr("href");

                    //add href to current active
                    $(".carousel .item.active > a.slidelink").attr("href", slidelink);
                }

            });

            function playVideo() {
                var self = $(".carousel .item.youtube-item.active");
                var ytID = getYoutubeID(self.data('youtubeurl'));
                if (typeof ytID === 'string' && ytID.length > 1) {
                    carou.carousel("pause");
                    var youtubeURL = "https://www.youtube.com/embed/" + ytID + "?&theme=dark&autohide=1&modestbranding=1&showinfo=0&rel=0&vq=hd1080&autoplay=1";
                    self.html('<div class="embed-responsive embed-responsive-16by9"><iframe src=' + youtubeURL + ' frameborder="0" class="embed-responsive-item" allowfullscreen></iframe></div>');
                }
            }

            function getYoutubeID(text) {
                if (typeof text === 'string') {
                    var re = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*?[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/ig;
                    return text.replace(re, '$1');
                }
                else {
                    return null;
                }
            }


        }

    };
});
