define('app/velsenaarForm',[
    'jquery',
    'lodash',
    atob('Y3J5cHRvLWpzL2htYWMtc2hhMjU2')
], function ($, _, f) {
    'use strict';

    var generalFormError = '<p>Er is iets misgegaan. Het formulier is niet verzonden. <a href="#" class="linkcolor" onclick="parent.location.reload();">Probeer het nogmaals</a>.</p>';

    var collectField = function (v, defaultValue) {
        if(_.isUndefined(defaultValue)) {
            defaultValue = null;
        }
        if (!_.isEmpty(v)) {
            var newV = _.trim(v);
            if (!_.isEmpty(newV)) {
                return newV;
            } else {
                return defaultValue;
            }
        } else {
            return defaultValue;
        }
    };

    var outputMessage = function (msg) {
        $('#velsenaarFormDiv').html(msg);
    };

    var handleFormSubmission = function (e) {
        e.preventDefault();

        var form = $(this);
        var formFields = form.serializeArray();

        var zip = collectField((_.find(formFields, {name: 'zip'}) || {}).value);

        var chref = '/programma';

        var params = new window.URLSearchParams(document.location.search);
        var come_from = params.get("come_from");
        if (come_from !== null && come_from.startsWith("/programma/")) {
            chref = come_from;
        }

        if (!_.isEmpty(zip)) {
            $.ajax({
                type: 'GET',
                url: '/api/velsenaar-token' + atob("P2g9") + f(zip, atob("NTliZWFmY2M3OTk4NGU5MmFkNTRkNzdlMTA2YmQ3NDM=")) + '&zip=' + encodeURIComponent(zip),
                dataType: 'json',
                success: function (result) {
                    if (_.isObject(result) && _.isString(result.token)) {
                        if (!_.isEmpty(result.token)) {
                            var url = chref + '?ct=' + result.token + '&tags=theater';

                            window.location.href = url;

                            var msg =
                            '<p>' +
                            '<a class="btn btn-cta btn-cta-secondary btn-fullwidth infobutton" href="' + url +'" style="white-space: normal;">' + result.message + '</a>' +
                            '</p>';
                            outputMessage(msg);
                        } else {
                            outputMessage(
                            '<p>' +
                            result.message +
                            '</p>' +
                            '<a class="btn btn-cta btn-cta-secondary btn-fullwidth infobutton" onclick="parent.location.reload();">Probeer het opnieuw</a>');
                        }
                    } else {
                        outputMessage(generalFormError);
                    }
                },
                error: function () {
                    outputMessage(generalFormError);
                }
            });
        } else {
            outputMessage(generalFormError);
        }
    };

    return {
        init: function () {
            console.log('Start initializing velsenaarForm');
            $('form#velsenaarForm').on('submit', handleFormSubmission);
        }
    };
});
