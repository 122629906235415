define('app/pagination',['jquery', 'lodash'], function ($, _) {
    'use strict';

    // var generalFormError = 'Er is iets misgegaan. <a href="#" class="linkcolor" onclick="parent.location.reload();">Probeer het nogmaals</a>.';

    var DATA_NEXT_PAGE_NUMBER = 'next-page-number';
    var DATA_NEXT_PAGE_HREF = 'next-page-href';
    var HEADER_NEXT_PAGE_NUMBER = 'X-Next-Page';

    return {
        init: function init() {
            console.log('Start initializing pagination');

            var prodListDiv = $('.prodlist').first();

            var btn = $('.pagination-more-btn');
            var loader = $('.pagination-more-loader');

            btn.on('click', function (e) {
                e.preventDefault();

                btn.hide();
                loader.show();

                var pbtn = $(e.target);
                var href = pbtn.data(DATA_NEXT_PAGE_HREF);

                var url = (_.startsWith(href, '/') ? '/api' : '/api/') + href;
                $.ajax({
                    type: "GET",
                    url: url,
                    success: function success(res, textStatus, req) {
                        var nextPageNr = req.getResponseHeader(HEADER_NEXT_PAGE_NUMBER);
                        prodListDiv.append(res);
                        if (window.history && window.history.pushState) {
                            window.history.pushState({ reload: true }, null, href);
                        }

                        // update next page
                        if (_.isString(nextPageNr) && !_.isEmpty(_.trim(nextPageNr))) {
                            var nextPageNrTrimmed = _.trim(nextPageNr);
                            // pbtn.attr('data-' + DATA_NEXT_PAGE_NUMBER, nextPageNrTrimmed);
                            pbtn.data(DATA_NEXT_PAGE_NUMBER, nextPageNrTrimmed);

                            var nextHref = href.replace(/pagina=\d+/, 'pagina=' + nextPageNrTrimmed);
                            // pbtn.attr('data-' + DATA_NEXT_PAGE_HREF, nextHref);
                            pbtn.data(DATA_NEXT_PAGE_HREF, nextHref);
                            btn.show();
                        } else {
                            btn.hide();
                        }
                    },
                    error: function error(err) {
                        console.log('error', err);
                        btn.show();
                    },
                    complete: function complete() {
                        loader.hide();
                    }
                });
            });
        }
    };
});
