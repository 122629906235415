define('app/ie9fix',[
    'jquery',
    'modernizr'
], function ($, Modernizr) {
    'use strict';

    return {
        init: function () {
            console.log('Start initializing ie9fix.js');

            //placeholders are not supported natively in IE9
            if (!Modernizr.input.placeholder) {

                $('[placeholder]').focus(function () {
                    var input = $(this);
                    if (input.val() === input.attr('placeholder') && input.attr('name') != 'niet_invullen') {
                        input.val('');
                        input.removeClass('placeholder');
                    }
                }).blur(function () {
                    var input = $(this);
                    if ((input.val() === '' || input.val() == input.attr('placeholder')) && input.attr('name') != 'niet_invullen') {
                        input.addClass('placeholder');
                        input.val(input.attr('placeholder'));
                    }
                }).blur();
                $('[placeholder]').parents('form').submit(function () {
                    $(this).find('[placeholder]').each(function () {
                        var input = $(this);
                        if (input.val() === input.attr('placeholder') && input.attr('name') != 'niet_invullen') {
                            input.val('');
                        }
                    });
                });

            }

        }
    };
});
