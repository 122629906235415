define('app/bootstrapselect',['jquery', 'bootstrap.select'], function ($) {
    'use strict';
    return {
        init: function () {
            console.log('Start initializing bootstrapselect.js');

            // set locale to NL for bootstrap select
            $.fn.selectpicker.defaults = {
                noneSelectedText: 'Niets geselecteerd',
                noneResultsText: 'Geen resultaten gevonden voor {0}',
                countSelectedText: '{0} van {1} geselecteerd',
                maxOptionsText: ['Limiet bereikt ({n} {var} max)', 'Groep limiet bereikt ({n} {var} max)', ['items', 'item']],
                multipleSeparator: ', '
            };

            $('.selectpicker').selectpicker({
                style: 'btn-default'
            });
        }
    };
});
