define('app/friendForm',[
    'jquery',
    'lodash',
    atob('Y3J5cHRvLWpzL2htYWMtc2hhMjU2')
], function ($, _, f) {
    'use strict';

    var generalFormError = '<p>Er is iets misgegaan. Het formulier is niet verzonden. <a href="#" class="linkcolor" onclick="parent.location.reload();">Probeer het nogmaals</a>.</p>';

    var collectField = function (v, defaultValue) {
        if(_.isUndefined(defaultValue)) {
            defaultValue = null;
        }
        if (!_.isEmpty(v)) {
            var newV = _.trim(v);
            if (!_.isEmpty(newV)) {
                return newV;
            } else {
                return defaultValue;
            }
        } else {
            return defaultValue;
        }
    };

    var outputMessage = function (msg) {
        $('#friendFormDiv').html(msg);
    };

    var handleFormSubmission = function (e) {
        e.preventDefault();

        var form = $(this);
        var formFields = form.serializeArray();

        var email = collectField((_.find(formFields, {name: 'email'}) || {}).value);

        var chref = '/programma';

        if (!_.isEmpty(email)) {
            $.ajax({
                type: 'GET',
                url: '/api/friend-token' + atob("P2g9") + f(email, atob("NTliZWFmY2M3OTk4NGU5MmFkNTRkNzdlMTA2YmQ3NDM=")) + '&email=' + encodeURIComponent(email),
                dataType: 'json',
                success: function (result) {
                    if (_.isObject(result) && _.isString(result.token)) {
                        if (!_.isEmpty(result.token)) {
                            var msg =
                            '<p>' +
                            '<a class="btn btn-cta btn-cta-secondary btn-fullwidth infobutton" href="' + chref +
                            '?ct=' + result.token + '&tags=theater" style="white-space: normal;">' + result.message + '</a>' +
                            '</p>';
                            outputMessage(msg);
                        } else {
                            outputMessage(
                            '<p>' +
                            result.message +
                            '</p>' +
                            '<a class="btn btn-cta btn-cta-secondary btn-fullwidth infobutton" onclick="parent.location.reload();">Probeer het opnieuw</a>');
                        }
                    } else {
                        outputMessage(generalFormError);
                    }
                },
                error: function () {
                    outputMessage(generalFormError);
                }
            });
        } else {
            outputMessage(generalFormError);
        }
    };

    return {
        init: function () {
            console.log('Start initializing friendForm');
            $('form#friendForm').on('submit', handleFormSubmission);
        }
    };
});
