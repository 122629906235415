define('app/searchFilters',[
    'jquery'
], function () {
    'use strict';

    return {
        init: function () {
            console.log('Start initializing searchFilters');
        }
    };

});
