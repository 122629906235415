define('app/analytics',[
    'jquery',
], function ($) {
    'use strict';

    var orderUrlRegex = /.*\/bestel\/.+\/\d{4}-\d{2}-\d{2}\/\d{2}:\d{2}/i;

    var decorate = function () {
        $("a.orderUrlLink").each(function () {
            var link = $(this);
            var href = $(link).attr('href');
            if (orderUrlRegex.test(href)) {
                ga('linker:decorate', link[0]);
            }
        });

        $("a.forceGaLink").each(function () {
            var link = $(this);
            ga('linker:decorate', link[0]);
        });
    };

    var trackDownloads = function () {
        $("a.trackdownloadclick").click(function (event) {
            event.preventDefault();
            if (typeof ga !== "undefined") {
                ga('send', 'event', {
                    eventCategory: 'Download',
                    eventAction: 'click',
                    eventLabel: event.target.href,
                    eventValue: 1,
                    transport: 'beacon'
                });
            }
            window.location = event.target.href;
        });
    };

    return {
        init: function () {
            console.log('Start initializing analytics.js...');

            // run once right away
            decorate();
            trackDownloads();

            // run every 60s
            setInterval(decorate, 60000);
        }
    };
});
