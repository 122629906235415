define('app/menu',[
    'jquery',
    'modernizr',
    'bootstrap.all'
], function ($, Modernizr) {
    'use strict';

    return {
        init : function () {
            console.log('Start initializing menu.js');

            //Show dropdown on hover only for desktop devices
            //-----------------------------------------------
            var delay=0, setTimeoutConst;
            if ((Modernizr.mq('only all and (min-width: 768px)') && !Modernizr.touch) || $("html.ie8").length>0) {
                $('.main-navigation:not(.onclick) .navbar-nav>li.dropdown, .main-navigation:not(.onclick) li.dropdown>ul>li.dropdown').hover(
                    function(){
                        var $this = $(this);
                        setTimeoutConst = setTimeout(function(){
                            $this.addClass('open').slideDown();
                            $this.find('.dropdown-toggle').addClass('disabled');
                        }, delay);

                    },	function(){
                        clearTimeout(setTimeoutConst );
                        $(this).removeClass('open');
                        $(this).find('.dropdown-toggle').removeClass('disabled');
                    });
            }

            //Show dropdown on click only for mobile devices
            //-----------------------------------------------
            if (Modernizr.mq('only all and (max-width: 767px)') || Modernizr.touch || $(".main-navigation.onclick").length>0 ) {
                $('.main-navigation [data-toggle=dropdown], .header-top [data-toggle=dropdown]').on('click', function(event) {
                    // Avoid following the href location when clicking
                    event.preventDefault();
                    // Avoid having the menu to close when clicking
                    event.stopPropagation();
                    // close all the siblings
                    $(this).parent().siblings().removeClass('open');
                    // close all the submenus of siblings
                    $(this).parent().siblings().find('[data-toggle=dropdown]').parent().removeClass('open');
                    // opening the one you clicked on
                    $(this).parent().toggleClass('open');
                });
            }

        }
    };

});
