define('app/forms',[
    'jquery'
], function ($) {
    'use strict';

    return {
        init: function () {
            console.log('Start initializing forms');

            var generalFormError = 'Er is iets misgegaan. Het formulier is niet verzonden. <a href="#" class="linkcolor" onclick="parent.location.reload();">Probeer het nogmaals</a>.';

            var collectJsonData = function (data, jsonData) {
                $.each(data, function () {
                    if (this.name !== "niet_invullen") {
                        jsonData[this.name] = this.value || null;
                    }
                    else if (this.value !== undefined && this.value !== null && this.value !== "") {
                        jsonData[this.name] = this.value || null;
                    }
                });
            };

            $('.gen-post-form').on('submit', function (e) {
                e.preventDefault();

                var form = $(this);
                var data = form.serializeArray();
                var url = form.attr('action');
                var idrespons = form.data('container');

                var jsonData = {};
                $.each(data, function () {
                    if (this.name !== "niet_invullen") {
                        jsonData[this.name] = this.value || null;
                    }
                    else if (this.value !== undefined && this.value !== null && this.value !== "") {

                        jsonData[this.name] = this.value || null;
                    }
                });

                if (!$.isEmptyObject(jsonData) && url && idrespons) {
                    $.ajax({
                        type: "POST",
                        url: url,
                        data: JSON.stringify(jsonData),
                        dataType: "json",
                        contentType: "application/json",
                        success: function (result) {
                            $("#" + idrespons).html('<p><strong>' + result.message + '</strong></p>');
                            //TODO: POST ga event
                        },
                        error: function (result) {
                            $("#" + idrespons).html('<p>' + (result.message || generalFormError) + '</p>');
                        }
                    });
                }
                else {
                    $("#" + idrespons).html('<p>' + generalFormError + '</p>');
                }


            });


            $('form#email-share-form').on('submit', function (e) {
                e.preventDefault();

                var form = $(this);
                var data = form.serializeArray();
                var url = form.attr('action');
                var idRespons = form.data('container');

                var jsonData = {};
                collectJsonData(data, jsonData);

                if (!$.isEmptyObject(jsonData) && url && idRespons) {
                    $.ajax({
                        type: "POST",
                        url: url,
                        data: JSON.stringify(jsonData),
                        dataType: "json",
                        contentType: "application/json",
                        success: function (result) {
                            $("#" + idRespons).html('<p><strong>' + result.message + '</strong></p>');
                        },
                        error: function (result) {
                            $("#" + idRespons).html('<p>' + (result.message || generalFormError) + '</p>');
                        }
                    });
                }
                else {
                    $("#" + idRespons).html('<p>' + generalFormError + '</p>');
                }
            });
        }
    };
});
